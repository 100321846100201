/*!   === Global ===   */

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css
// Normalization and Global styling.

// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
//    we force a non-overlapping, non-auto-hiding scrollbar to counteract.
// 6. Change the default tap highlight to be completely transparent in iOS.

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

html,
body {
  font-family: sans-serif; // 2
}

html {
  -ms-overflow-style: scrollbar; // 5
  line-height: var(--lh-primary); // 3
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent; // 6
  -webkit-text-size-adjust: 100%; // 4
  -ms-text-size-adjust: 100%; // 4
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Set an explicit initial text-align value so that we can later use the the `inherit` value on things like `<th>` elements.
body {
  background-color: var(--white); // 2
  font-size: var(--fs-16);
  margin: 0; // 1
  color: var(--anis);
  line-height: normal;
  text-align: left; // 3
}

// Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers)
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

// Content grouping
//
// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.
hr {
  height: 0; // 1
  max-width: 1680px; // CUSTOM
  margin: 0 auto;
  overflow: visible; // 2
  box-sizing: content-box; // 1
  border-color: var(--gray200);
}

// Typography

// Remove top margins from headings
//
// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
  margin-top: 0;
}

// Links
a {
  background-color: transparent; // Remove the gray background on active links in IE 10.
  color: inherit;
  text-decoration: none; // Custom
  &:hover {
    text-decoration: none; // Remove default links underlines.
  }
}

svg {
  // Workaround for the SVG overflow bug in IE10/11 is still required.
  // See https://github.com/twbs/bootstrap/issues/26878
  overflow: hidden;
  vertical-align: middle;
}

// Remove the gap between audio, canvas, iframes,
// images, videos and the bottom of their containers:
// https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
iframe,
svg,
video {
  vertical-align: middle;
}

// Images and content
img {
  border-style: none; // Remove the border on images inside links in IE 10-.
  vertical-align: middle;
}

em {
  font-style: normal;
}

img {
  user-select: none;
}

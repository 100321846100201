@import "scss/mixins";

html {
  scroll-snap-type: y mandatory;
}

.landing-page {
  color: var(--btint);
  .landing-container {
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
  .top-banner {
    .padded-container {
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      .top-logo {
        position: absolute;
        top: 2rem;
        left: 50%;
        transform: translateX(-50%);
        img {
          height: 2.75rem;
          width: auto;
        }
        @include mobile {
          top: 3.5rem;
          img {
            height: 2rem;
          }
        }
      }

      .spinninghead-cnt {
        img {
          height: 24rem;
          width: auto;
          @include mobile {
            height: 15rem;
          }
        }
      }
    }
  }

  // mid area (common) css begins here
  .mid-area {
    .padded-container {
      display: flex;
      flex-direction: row;
      @include mobile {
        flex-direction: column;
      }

      .tint-cnt {
        width: 50%;
        background-color: var(--wtint);
        color: var(--btint);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include mobile {
          width: 100%;
          height: 50%;
        }
        .our-title {
          font-family: var(--ff-joy-ext);
          text-transform: uppercase;
          font-size: 2.7rem;
          text-wrap: nowrap;
          margin-bottom: 2rem;
          @include mobile {
            font-size: 1.2rem;
            margin-bottom: 1.75rem;
          }
        }
        .desc {
          font-family: var(--ff-arc-reg);
          font-size: 0.95rem;
          margin-bottom: 4.5rem;
          @include mobile {
            text-align: center;
            margin-bottom: 2.5rem;
            font-size: 0.75rem;
            max-width: 90%;
          }
        }
        .ptext {
          font-family: var(--ff-arc-reg);
          font-size: 0.95rem;
          @include mobile {
            color: var(--white);
            font-size: 0.7rem;
          }
        }

        .bottomrightlogo {
          position: absolute;
          bottom: 30px;
          left: 25px;
          @include mobile {
            // display: none;
            position: absolute;
          }
          img {
            height: 45px;
            width: auto;
          }
        }
        @include mobile {
          .bottommidlogo {
            margin-top: 1.5rem;
            img {
              height: 35px;
              width: auto;
            }
          }
        }

        .bottomleftlogo {
          position: absolute;
          bottom: 30px;
          right: 25px;
          @include mobile {
            display: none;
          }
          img {
            height: 45px;
            width: auto;
          }
        }
      }

      .slide-cnt {
        width: 50%;
        position: relative;
        display: flex;
        align-items: center;
        @include mobile {
          width: 100%;
          height: 50%;
        }
      }
    }
  }

  // mid area (A) css begins here

  .mid-area-a {
    .padded-container {
      .tint-cnt {
        .three-img-cnt {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;

          .twopics {
            display: flex;
            gap: 0.5rem;
          }
          img {
            width: 10.5rem;
            height: auto;
          }
          @include mobile {
            flex-direction: row;
            img {
              width: 6rem;
              height: auto;
            }
          }
        }
      }

      .slide-cnt {
        flex-direction: column;
        justify-content: flex-end;
        gap: 2.5rem;
        .project-logo {
          img {
            height: 135px;
            width: auto;
          }
        }
        .slide-img {
          width: 100%;
          img {
            height: auto;
            width: 100%;
          }
        }
        .icon-cnt {
          position: absolute;
          bottom: 20px;
          display: flex;
          flex-direction: row;
          gap: 10px;

          .icon-box {
            background-color: var(--white);
            padding: 9px;
            border-radius: 8px;
            cursor: pointer;
            &:hover {
              filter: brightness(0.87);
            }
            img {
              height: 35px;
              width: 35px;
            }
          }
        }
        @include mobile {
          flex-direction: column;
          justify-content: flex-end;
          gap: 1.2rem;
          .project-logo {
            img {
              height: 80px;
              width: auto;
            }
          }
          .slide-img {
            width: 100%;
            img {
              height: auto;
              width: 100%;
            }
          }
          .icon-cnt {
            position: absolute;
            bottom: 20px;
            display: flex;
            flex-direction: row;
            gap: 10px;

            .icon-box {
              background-color: var(--white);
              padding: 9px;
              border-radius: 8px;
              cursor: pointer;
              padding: 7px;
              border-radius: 8px;
              img {
                height: 28px;
                width: 28px;
              }

              &:hover {
                filter: brightness(0.87);
              }
              // img {
              //   height: 35px;
              //   width: 35px;
              // }
            }
            @include mobile {
              bottom: 20px;
              gap: 8px;
            }
          }
        }
      }
      .bullclubslide-cnt,
      .firebirdslide-cnt {
        background-color: var(--black);
      }

      .babybullslide-cnt {
        background: linear-gradient(180deg, #7f63ad 0%, #4f2f83 100%);
      }
    }
  }

  // mid area (B) css begins here
  .mid-area-b {
    .padded-container {
      .slide-cnt,
      .blackarea {
        background-color: var(--black);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 380px;
          width: auto;
        }
      }
      @include mobile {
        flex-direction: column-reverse;
        .blackarea {
          img {
            height: 220px;
            width: auto;
            margin-bottom: 6rem;
          }
        }
      }
      .tint-cnt {
        .tools-title {
          font-family: var(--ff-joy-wide);
        }
        .tools-list {
          .boxarea {
            margin-top: -2rem;
            margin-bottom: -2rem;
            display: flex;
            flex-direction: column;
            gap: 2.25rem;
            @include mobile {
              margin-top: 0rem;
              margin-bottom: 0rem;
              gap: 2rem;
            }
            .grey-box-btn-cnt {
              position: relative;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              .grey-box-cnt {
                background-color: var(--btint);
                color: var(--white);
                font-family: var(--ff-unison-bold);
                width: 25rem;
                padding: 2rem 0rem;
                font-size: 1.25rem;
                cursor: pointer;
                &:hover {
                  filter: brightness(0.92);
                }
                @include mobile {
                  width: 15rem;
                  padding: 1.5rem 0rem;
                  font-size: 0.8rem;
                }
              }
              .coming-soon {
                background-color: var(--white);
                color: var(--btint);
                font-family: var(--ff-helve);
                padding: 0.4rem 0.9rem;
                position: absolute;
                bottom: -10px;
                right: 5px;
                transform: rotate(-13.5deg);
                @include mobile {
                  transform: scale(0.9);
                  font-size: 0.8rem;
                  padding: 0.4rem 0.7rem;
                  position: absolute;
                  bottom: -8px;
                  right: 10px;
                  transform: rotate(-11deg);
                }
              }
            }
          }

          .interested-connect-with-us {
            margin-top: 8rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            text-align: center;
            @include mobile {
              position: absolute;
              bottom: 4rem;
              left: 50%;
              transform: translateX(-50%);
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  // contact area css begins here

  .contact-area {
    .padded-container {
      background-color: var(--wtint);
      display: flex;
      flex-direction: row;
      position: relative;
      @include mobile {
        flex-direction: column;
      }
      .side {
        width: 50%;
        @include mobile {
          width: 100%;
        }
      }
      .lets-talk {
        margin-top: 7rem;
        padding-left: 3rem;
        @include mobile {
          height: 35%;
          margin-top: 1.75rem;
          padding-left: 2rem;
        }
        .vbig-txt {
          font-family: var(--ff-joy-ext);
          font-size: 8rem;
          @include mobile {
            font-size: 3.5rem;
          }
        }
        .logos-links {
          margin-top: 4rem;
          font-family: var(--ff-arc-bd);
          display: flex;
          flex-direction: column;
          gap: 1.25rem;
          max-width: fit-content;
          .link {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            .bullhead {
              height: 55px;
              width: 55px;
              cursor: pointer;
            }
            .xtwit {
              height: 50px;
              width: 50px;
              cursor: pointer;
            }
            .link-txt {
              font-size: 1rem;
              cursor: pointer;
            }
          }
          @include mobile {
            margin-top: 1.5rem;
            gap: 1rem;
            .link {
              gap: 1rem;
              .bullhead {
                height: 38px;
                width: 38px;
              }
              .xtwit {
                height: 33px;
                width: 33px;
              }
              .link-txt {
                font-size: 0.85rem;
                cursor: pointer;
              }
            }
          }
        }
      }

      .form-area {
        display: flex;
        flex-direction: column;
        margin-top: 8.7rem;
        padding-right: 1rem;
        position: relative;

        @include mobile {
          position: relative;
          margin-top: 0.75rem;
          padding-left: 0rem;
          padding-right: 0rem;
          overflow: visible;
          // transform: scale(0.85);
          // margin-left: 6rem;
        }
        .centered-form {
          @include mobile {
            margin-left: 0rem;
            // transform: scale(0.85);
          }
        }

        .connect-txt {
          margin-top: 0rem;
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          font-family: var(--ff-joy-std);
          align-items: center;
          font-size: 1rem;
          text-transform: uppercase;
          img {
            @include mobile {
              height: 25px;
              width: auto;
              margin-left: 1.9rem !important;
            }
          }
          @include mobile {
            font-size: 1.275rem;
            // padding-left: 0.4rem;
          }
        }

        .form {
          @include mobile {
            display: flex;
            // justify-content: center;
            flex-direction: column;
          }
          .four-items {
            max-width: 95%;
            margin-top: 4.5rem;
            margin-bottom: 4.5rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 35px;
            @include mobile {
              max-width: 100%;
              margin-top: 2.5rem;
              margin-bottom: 2rem;
              row-gap: 30px;
              justify-content: center;
              padding-left: 0.5rem !important;
              // margin-left: 1rem;
            }
          }

          .one-item {
            display: flex;
            flex-direction: column;
            width: 85%;
            @include mobile {
              width: 75%;
            }
            .title {
              text-transform: uppercase;
              font-family: var(--ff-arc-ex-bd);
              font-size: 0.9rem;
              @include mobile {
                font-size: 0.72rem;
              }
            }
            // resets input area
            .input-txt {
              margin: 0;
              padding: 0;
              border: none;
              outline: none;
              background: none;
              font-family: inherit;
              font-size: 100%;
              color: inherit;
              border-bottom: 1px solid var(--gray200);
              padding-top: 0.65rem;
              padding-bottom: 10px;
              font-family: var(--ff-arc-reg);
              font-size: 1rem;
              &::placeholder {
                color: var(--gray100);
              }
              @include mobile {
                font-size: 0.72rem;
              }
            }
            select {
              color: black;
            }
            select:invalid {
              color: var(--gray100);
            }
          }
          .left-item {
            @include mobile {
              margin-left: 1.5rem !important;
            }
          }
          .message-area {
            position: relative;

            .one-item {
              width: 95%;
              @include mobile {
                // margin-left: 0.8rem;
                width: 82%;
                margin-left: 2rem !important;
              }
            }

            .message-txt {
              padding-bottom: 7rem !important;
              resize: none;
              scrollbar-width: thin; /* For Firefox */
              -ms-overflow-style: none; /* For Internet Explorer and Edge */
              &::-webkit-scrollbar {
                width: 0rem;
              }
              @include mobile {
                padding-bottom: 10.5vh !important;
                margin-bottom: 10px;
              }
            }
            .send-button {
              position: absolute;
              bottom: 15px;
              right: 30px;
              padding: 1rem;
              background-color: var(--white);
              border-radius: var(--b-rad-round);
              cursor: pointer;
              border: 1px solid gainsboro;
              &:hover {
                filter: brightness(0.95);
                border: 1px solid rgba(220, 220, 220, 0);
              }
              img {
                width: 30px;
                height: 30px;
              }
              @include mobile {
                bottom: 15px;
                right: 30px;
                padding: 0.75rem;
                img {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }
        }
      }

      .end-footer-txt {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: var(--ff-arc-reg);
        color: var(--gray100);
        font-size: 0.88rem;
      }
      @include mobile {
        .finalfooter {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .bottommidlogo {
            position: absolute;
            bottom: 17px;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .end-footer-txt {
            position: absolute;

            font-size: 0.8rem;
            white-space: nowrap;
            bottom: 8px;

            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

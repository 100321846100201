:root {
  //* Fonts
  // Families :
  // Local
  --ff-arc-reg: "Archivo Regular", sans-serif;
  --ff-arc-bd: "Archivo Bold", sans-serif;
  --ff-arc-ex-bd: "Archivo Extra Bold", sans-serif;
  --ff-nimbus: "Nimbus Sans Black", sans-serif;
  --ff-joy-std: "Joyride STD", sans-serif;
  --ff-joy-ext: "Joyride EXT", sans-serif;
  --ff-joy-wide: "Joyride WIDE", sans-serif;
  --ff-unison-bold: "Unison Pro Bold Round", sans-serif;
  --ff-helve: "Helvetica Neue Black Condensed", sans-serif;
  // Sizes
  --fs-10: 10px;
  --fs-11: 11px;
  --fs-12: 12px;
  --fs-13: 13px;
  --fs-14: 14px;
  --fs-15: 15px;
  --fs-16: 16px;
  --fs-18: 18px;
  --fs-20: 20px;
  --fs-22: 22px;
  --fs-24: 24px;
  --fs-26: 26px;
  --fs-30: 30px;
  --fs-36: 36px;
  --fs-38: 38px;
  --fs-40: 40px;
  --fs-44: 44px;
  --fs-50: 50px;
  --fs-56: 56px;
  --fs-146: 146px;
  // Weights
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  // Line heights
  --lh-base: 1;
  --lh-primary: 1.2;
  --lh-secondary: 1.1;
  --lh-22: 22px;

  // Colors

  // Primary
  --white: #fff;
  --black: #000;
  --wtint: #f1f1e7;
  --btint: #212936;
  --gray100: #a5a59c; // !
  --gray200: #bababa;

  // Extras
  --pink: #ff006c;
  --blue: #515bcf;
  --purple: #8247e5;
  --success: #00c96b;

  // Border-radius

  --b-rad-round: 50%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    text-wrap: nowrap;
    &:hover {
      filter: brightness(0.92);
    }
  }
  .white-theme {
    font-family: var(--ff-joy-ext);
    color: var(--btint);
    background-color: var(--white);
    transform: scale(0.85);
    padding: 0.87rem 1.5rem;
    font-size: 0.8rem !important;
  }

  .default-theme {
    font-family: var(--ff-unison-bold);

    background-color: var(--btint);
    color: var(--white);
  }

  .regular-size-button {
    padding: 0.9rem 1.5rem;
    font-size: 0.7rem;
  }

  .big-size-button {
    // according to need
  }
}

@import "mixins";

body {
  max-width: 100vw;
  overflow-x: hidden;
  // cursor: url("../assets/img/cursor/customcursor.svg"), auto;
}

.landing-container {
  height: 100vh;
  // max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.padded-container {
  height: 96vh;
  width: 97vw;
  overflow: hidden;
  border-radius: 0.6rem;
  position: relative;
  // position: fixed !important;
  // top: 0;
  // margin-top: 15px;
  @include mobile {
    height: 95vh;
    width: 92vw;
  }
}

// div,
// p {
//   cursor: default;
// }

::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--gray200);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6a6a6a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

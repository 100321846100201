@font-face {
  font-family: "Archivo Regular";
  font-style: normal;
  font-weight: var(--fw-300);

  src: url("../assets/fonts/Archivo-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Archivo Bold";
  font-style: normal;
  font-weight: var(--fw-400);

  src: url("../assets/fonts/Archivo-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Archivo Extra Bold";
  font-style: normal;
  font-weight: var(--fw-500);

  src: url("../assets/fonts/Archivo-ExtraBold.woff2") format("woff2");
}

@font-face {
  font-family: "Joyride STD";
  font-style: normal;
  font-weight: var(--fw-300);

  src: url("../assets/fonts/Joyride-STD.woff2") format("woff2");
}


@font-face {
  font-family: "Joyride EXT";
  font-style: normal;
  font-weight: var(--fw-400);

  src: url("../assets/fonts/Joyride-EXT.woff2") format("woff2");
}

@font-face {
  font-family: "Joyride WIDE";
  font-style: normal;
  font-weight: var(--fw-500);

  src: url("../assets/fonts/Joyride-WIDE.woff2") format("woff2");
}

@font-face {
  font-family: "Helvetica Neue Black Condensed";
  font-style: normal;
  font-weight: var(--fw-500);

  src: url("../assets/fonts/Helvetica-Neue-Black-Condensed.woff2")
    format("woff2");
}

@font-face {
  font-family: "Unison Pro Bold Round";
  font-style: normal;
  font-weight: var(--fw-500);

  src: url("../assets/fonts/Unison-Pro-Bold-Round.woff2") format("woff2");
}
